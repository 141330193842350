Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.exampleAPiEndPoint = "EXAMPLE_URL";

exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LikeAPost";
exports.labelBodyText = "LikeAPost Body";
exports.aiTitle = "Let the AI Work its Magic!";
exports.contentAI = "Sit back and relax this takes a moment. Remember, you can always refine your plan later!";
exports.seoTitle = "Setup SEO";
exports.storeName = "store_name"
exports.storeLabel = "Store Name";
exports.storePleaceholder = "Enter name of your amazing business.";
exports.businessContentLabel = "Business Category";
exports.pregressCompleted = "50% Completed";
exports.determinate = "determinate";
exports.seoSubTitle = "Answer a few questions and our AI will craft a SEO recommendations for your online store";
exports.buttonLabel = "Continue";
exports.aboutName = "about_business";
exports.aboutLabel = "Tell us about your business";
exports.aboutPlaceHolder = "Please provide a brief description of your business, including your industry, target market, and key products or services.";
exports.businessContentPlaceholder = "What are 3 categories your business could fall under? (e.g., clothing store, beauty)";
exports.btnExampleTitle = "CLICK ME";
exports.postAPIMethod = "POST";
exports.getAPIMethod = "GET";
exports.patchAPIMethod = "PATCH";
exports.deleteAPIMethod = "DELETE";
exports.putAPIMethod = "PUT";
exports.apiPathname = "/bx_block_seo_setting/seo_settings";
exports.seoHeading = "Setup SEO";
exports.editBussinessInfoButton = "Edit Business Info";
exports.siteInfoTitle='General SEO settings';
exports.siteInfoDescription = "Add SEO friendly title, page description and keywords update";
exports.siteMapsTitle="Sitemaps";
exports.siteMapsDescription = "Sitemaps help search engines find the most important pages of your site";
exports.configPagesTitle = "Configure SEO Pages";
exports.configPagesDescription = "Customize your default page settings and meta data";
exports.hurryUpText="Hurray, you’re all done!";
exports.uploadNeededMsg = "Update Needed";
exports.configureHeading = "Configure SEO for pages";
exports.backButton = "Back";
exports.aiDescriptionChip = "AI-Generated";
exports.editAlterText = "Edit";
exports.altImage = "image";
exports.editConfigureText = "Optimize your site's SEO and user engagement with custom titles and meta descriptions";
exports.titleRequiredText = "Title *";
exports.titlePlaceholderText = "Unfilled | Title Placeholder";
exports.metaText = "Tell people about the site *";
exports.metaTextPlaceholder = "Meta Description: Craft the perfect title and description to boost your SEO! Replace this placeholder text with a compelling summary of your content.";
exports.generateWithAIText = "Generate with AI";
exports.tooltipAltText = "Tooltip";
exports.optionText = "Option ";
exports.previewHeading = "Preview";
exports.exploreContent = `
Explore a world of endless possibilities at shop & shop, 
your premier destination for top-quality products.
 Shop with confidence for a diverse range of categories, unbeatable ...
`;
exports.homeText = "Home | Ultimate Destination for Premium Quality ";
exports.cardTitle = "Title";
exports.cardDescription = "https://websitename.com > title";
exports.Stitle = "S";
exports.editTitleTooltipText = "The headline for your page in search results and browser tabs.";
exports.editDescriptionTooltipText = "A brief summary of your page content that appears in search results. ";
exports.descriptionToolTipText = "Keywords are search terms people use to find your website. Like tags for search engines.";
exports.inputLabelText = "/65";
exports.inputLabelText2 = "/160";
exports.adminToken = "admintoken";
exports.configureSeoListEndPoint = "/bx_block_seo_setting/seo_settings";
exports.showGeneralSettingsStatusEndPoint = "/bx_block_seo_setting/general_seo_settings/show_status";
exports.storeDetailsEndPoint = "/bx_block_store_details/store_details";
exports.createGeneralSettingsEndPoint = "/bx_block_seo_setting/general_seo_settings";
exports.getSeoSetttingInfoEndPoint = "/bx_block_seo_setting/general_seo_settings/show_data";
exports.aiKeywordsGenrationEndPoint = "bx_block_seo_setting/general_seo_settings/generate_keywords";
exports.sitemapsDowload = "/bx_block_seo_setting/sitemap_urls/download_sitemap";
exports.updateGeneralSettings = "/bx_block_seo_setting/general_seo_settings/update_data";
exports.starAlt = "star";
exports.cloasAlt="close";
exports.plusAlt = "plus";
exports.aiGenerateTitleListTwo = [
  {
    id:1,
    name:`
    Explore a world of endless possibilities at shop & shop, your premier destination for top-quality products. Shop with confidence for a diverse range of categories, unbeatable prices, and seamless shopping experiences. Elevate your lifestyle with us – where choices meet convenience!
    `,
    isSelected:true,
  },
  {
    id:2,
    name:`Dive into a treasure trove of endless options at Best Buy Now, your trusted source for premium products. Browse with confidence through a vast array of categories, knowing you'll always find unbeatable deals. We prioritize a smooth shopping journey, putting convenience at your fingertips.`,
    isSelected:false,
  }
];

exports.aiGenerateTitleList = [
  {
    id:1,
    name:"Your Home for Fitness Essentials",
    isSelected:true,
  },
  {
    id:2,
    name:"Discover Quality in Clothing",
    isSelected:false,
  },
  {
    id:3,
    name:"Curated Fashion Finds | Delivered",
    isSelected:false,
  },
  {
    id:4,
    name:"Elevate Your Kitchen with Cookware Direct",
    isSelected:false,
  }
]
exports.tableData = [
  {
    id:1,
    name:"Page Name",
    tooltip:"",
  },
  {
    id:2,
    name:"Page URL",
    tooltip:"",
  },
  {
    id:3,
    name:"Title Tag",
    tooltip:"The headline for your page in search results and browser tabs.",
  },
  {
    id:4,
    name:"Meta Description",
    tooltip:"A brief summary of your page content that appears in search results. ",
  },
  {
    id:5,
    name:"Edit",
    tooltip:"",
  },
]
exports.defaultTableData = [];
exports.backButton = "Back";
exports.sitemaps = "Sitemaps";
exports.siteoptimizeHead = "Your sitemaps are optimized";
exports.searchEngineDetails = "We've made search engine discovery easy with automatic sitemaps, ensuring all your content gets seen.";
exports.automatic = "Automatically generated sitemaps guide them to every corner of your site, ensuring all your content gets found.";
exports.siteMapManual = "No need to build or update sitemaps manually, it all happens behind the scenes.";
exports.newContent = "New content gets added to the sitemaps automatically, so search engines have the latest info about your site.";
exports.searchCheckbox = "Search engines can discover and index all the images on your pages, helping them get seen too.";
exports.viewSiteButton = "View sitemap";
exports.sitemapSubmission = "Sitemap Submission Update";
exports.googleConsole = "Updated sitemap submitted to Google Console on May 12, 2024."
exports.metaTitleName = "metaTitle";
exports.metaDescriptionName = "metaDescription";
exports.saveChanges="SAVECHANGES";
exports.wrongDataFound = "Invalid responce received from server";
exports.configureTitleErrorMessage = "Title can not be empty.";
exports.configureDescriptionErrorMessage = "Description can not be empty."
exports.storeNameErrorMessage = "Store name can not be empty."
exports.businessDetailsErrorMessage = "Business details can not be empty."
exports.detailsUpdationSuccessMessage = "SEO general settings updated successfully.";
exports.addNewButtonText = "Add New";
exports.pressEnterInfoText = "Please press on Enter key to add new title.";
exports.keywordsText = "Keywords";
exports.downloadSiteMapTitle = "Download Sitemap";
exports.downloadSiteDescription = "We've made search engine discovery easy with automatic sitemaps, ensuring all your content gets seen.";
exports.warningMessageConfigureSEO = "Complete all required updates in the General SEO settings before accessing Configure SEO pages."
exports.goToSitemapBtn = "View Sitemap";
// Customizable Area End