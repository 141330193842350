import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  Grid,
  TextField,
  Modal,
  Breadcrumbs,
  IconButton,
  styled,
  Divider
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';


import { account, location, order, logout, deleteImage, checkbox } from './assets'
import ResendTimer, { startResendTimer, handleSendMagicLink, handleSendForgotPasswordLink, handleKeyPress } from "../../../components/src/ResendTimer.web";
export const configJSON = require("./config");

const webstyle = {
  cursorPointer: {
    cursor: "pointer",
  },
  paddingRemove: {
    padding: 0,
  },
  image: {
    width: "16px",
    height: "16px",
    objectFit: "fill" as "fill",
  },
  profileImageContaier: {
    display: "flex",
    gap: ".6rem",
    flexShrink: 0,
    flexBasis: "190px",
    alignItems: "center",
    cursor: "pointer",
  },
  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
    gap: "1rem",
    height: "56px",
  },
  blackColor: {
    color: "var(--design-secondary-color)"
  },
  borderBottomContainer: {
    padding: ".5rem 0",
    height: "56px",
    borderBottom: "1px solid #E2E8F0"
  },
  profileHeading2: {
    color: "#64748B",
    fontWeight: "700",
  },
  MainBox:{
    marginTop: 'calc(var(--mobile-header-height, 64px) - 20px)',
    boxShadow: '24px',
    border:'none',
    borderRadius:"12px",
    padding: '20px',
    display:"flex",
    overflow:'scroll' as 'scroll',
    justifyContent:"center",
    alignItems:"center",
    height: "396px",
    position: "relative" as "relative",
  },
  magicBox: {
    height: "calc(100% - var(--mobile-header-height, 64px))",
    display: 'block',
  },
  desktopBox: {
    width: "100%",
    height: "100vh",
    marginTop: "0",
  },
  mainHeadinggrid:{
    margin:"3% 0"
  },
  mainHeadinggridFPw:{
    marginBottom:"10px"
  },
  mainHeading:{
    fontFamily: "var(--design-header-text-font)",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    textAlign: "left" as 'left',
    color:"var(--design-secondary-color)"
  },
  mainHeadingFP:{
    fontFamily: "var(--design-header-text-font)",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    textAlign: "center" as 'center',
    color:"var(--design-secondary-color)"
  },
  firstNametext:{
    fontFamily: "var(--design-body-text-font)",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "32px",
    textAlign: "left" as 'left',
    color:"var(--design-secondary-color)",
  },
  mainButtongrid:{
    display:"flex",
    justifyContent:"center",
  },
  mainButtongridForgotPw:{
    display:"flex",
    justifyContent:"center",
    marginTop:"10px"
  },
  buttonText:{
    background: "var(--design-primary-color)",
    padding: "10px 16px 10px 16px",
    borderRadius: "8px",
    width:"100%",
    fontFamily: "var(--design-body-text-font)",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "32px",
    color:"#FFFFFF",
    textTransform:'none' as 'none',
    marginTop: "auto",
    height: "56px",
  },
  mainlogingrid:{
    margin:"2% 0"
  },
  loginMaintext:{
    fontFamily: "var(--design-body-text-font)",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "22px",
    color:"var(--design-secondary-color)",
    paddingTop:'10px'
  },
  loginMaintextFP:{
    fontFamily: "var(--design-body-text-font)",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "22px",
    color:"var(--design-secondary-color)",
    paddingTop:'10px',
    textAlign:'center' as 'center'
  },
  loginSubtext:{
    fontFamily: "var(--design-body-text-font)" as "Inter",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "32px",
    color:"var(--design-primary-color)",
    marginLeft:"1%",
    cursor:'pointer'
  },
  emailInput:{
    border: '1px solid #CBD5E1',
    borderRadius:'8px',
    color:'#000000',
    padding:'15px 10px',
    width:'100%',
    height: "56px",
    fontSize:'16px',
    margin:'5px 0 5px',
    "&:placeholder":{
      color:'#64748B',
    },
    outline:'none',
    boxSizing: "border-box" as "border-box",
  },
  emailErrorField:{
    border: '1px solid #F87171',
    borderRadius:'8px',
    color:'#64748B',
    padding:'15px 10px',
    width:'100%',
    fontSize:'16px',
    margin:'5px 0 0',
    outline:'none',
    boxSizing: "border-box" as "border-box",
  },
  emailErrorText:{
    fontSize:'12px',
    color:'#DC2626',
    margin:'1px 1px 20px'
  },
  emailText:{
    fontWeight:500,
    color: 'var(--design-primary-color)',
    marginTop:'15px',
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "1" as "1",
    WebkitBoxOrient: "vertical" as "vertical",
    wordBreak:"break-word" as "break-word",

  },
  passwordField:{
    background:'#fff'
  },
  inputProps:{
    borderRadius:"8px",
    borderColor:'#CBD5E1',
  },
  passInputProps:{
    padding:'17px'
  },
  eyeIcon:{
    color:'#94A3B8',
    cursor:'pointer'
  },
  forgotPassword:{
    fontSize:'14px',
    color:'var(--design-primary-color)',
    fontWeight:700,
    cursor:'pointer',
    textAlign:'end' as 'end',
    padding:'5px 0 20px'
  },
  dividerBox:{
    display:'flex',
    alignItems:'center',
    gap:'20px',
    margin:'20px 0'
  },
  hrLine:{
    width:'80%',
    height:0,
    color:'#E2E8F0'
  },
  orText:{
    fontSize:'14px',
    fontWeight:700,
    color:'#64748B'
  },
  mainContainer:{
    padding: "40px",
    width: "min(440px, 100%)",
    backgroundColor: "#fff",
    borderRadius: "12px",
    position: "relative" as "relative",
    margin: "0 auto",
  },
  mobileMainContainer:{
    paddingTop: "40px",
    width: "min(440px, 100%)",
    backgroundColor: "#fff",
    borderRadius: "12px",
    position: "relative" as "relative",
    margin: "0 auto",
  },
  backgroundNone: {
    backgroundColor: "transparent",
  },
  loginFormMainContainer: {
    height: "calc(100% - var(--mobile-header-height, 64px))",
    },
  magicLinkMainContainer: {
    padding: "0px 20px 20px 20px",
    display: "flex",
    backgroundColor: "transparent",
  },
  longPassword:{
    fontWeight:600,
    color:'var(--design-secondary-color)',
    margin:'10px 0',
    textAlign:'center' as 'center'
  },
  maginlinkdetails:{
    fontSize:'14px',
    lineHeight:'24px',
    color:'var(--design-secondary-color)',
    textAlign:'center' as 'center',
    marginBottom:'20px'
  },
  mailmeButton:{
    background:'#F0E5FF',
    borderRadius:'8px',
    fontWeight:700,
    color:'var(--design-primary-color)',
    textTransform:'capitalize' as 'capitalize',
    width:'100%',
    boxShadow:'none',
    padding:'15px'
  },
  backtotext:{
    color:'var(--design-secondary-color)',
    padding:'15px 0 10px'
  },
  backButttonTextAlign: {
    textAlign: "center",
  },
  loginSpan:{
    color:'var(--design-primary-color)',
    paddingLeft:'5px',
    fontWeight:700,
    cursor:'pointer'
  },
  noAccountBox:{
    background:'#FEE2E2',
    color:'#DC2626',
    borderLeft:'4px solid #DC2626',
    padding:'12px',
    fontSize:'12px',
    margin:'10px 0',
    borderRadius:'5px',
  },
  MoBileMainBox:{
    padding:'40px 20px',
  },
  mobileBackground: {
    backgroundColor:'#ffffff',
    marginTop: "var(--mobile-header-height, 64px)",
    height: "calc(100% - var(--mobile-header-height, 64px))",
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: "0 20px",
  },
  desktopBackground: { 
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  weMailedText: {
    color: 'var(--design-secondary-color)',
    fontFamily: "var(--design-body-text-font)",
  },
  emailtext: {
    fontWeight: 600,
    color: 'var(--design-primary-color)',
    marginLeft: '5px',
    fontFamily: "var(--design-body-text-font)",
  },
  checkMail:{
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "26px",
    textAlign: "left" as 'left',
    color:"var(--design-secondary-color)",
    margin:'10px 0',
    fontFamily: "var(--design-body-text-font)",
  },
  closeIcon: {
    position: "absolute" as "absolute",
    right: "1%", 
    top: "1%"
  },
  closeIconMobile: {
    top: "3%",
    right: "3%",
    zIndex: 1,
  },
  fullWidthContainer: {
    width: "100%"
  },
  userProfileContainer: {
    width: "100%",
    borderRadius: "12px",
    position: "relative" as "relative",
    margin: "0 auto",
    paddingTop: "68px",
  },
  paddingVertical: {
    padding: "0 20px 10px 20px"
  },
  paddingType2: {
    padding: "0 10px 0 20px",
  },
  flexTwoCon: {
    display: "flex",
    alignItems: "flex-end",
  },
  titleContainer: {
    marginTop: "1rem",
    marginBottom: "-1rem",
  },
  userProfileClose: {
    position: "absolute" as "absolute",
    right: "12px", 
    top: "10%",
  },
  redColor: {
    color: "#DC2626"
  },
  body2: {
      fontFamily: "var(--design-body-text-font)",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "22px",
      color: "#475569", 
  },
  marginBottom2: {
    marginBottom: "2.5rem"
  },
  pointer:{
    cursor: "pointer",
  },
  errorMsgText: {
    fontFamily: "var(--design-body-text-font)",
  },
  successImg: {
    width: "40px",
    height: "40px",
    margin: "0 auto",
    display: "block",
    marginBottom: "20px"
  },
  magicLinkMainWrapper: {
    marginTop: '20px'
  },
  magicLinkDividerWrapper: {
    display:'flex',
    gap:'20px',
    alignItems:'center',
  },
  magicLinkDivider: {
    color:'#E2E8F0',
    flexGrow: 1,
  },
  orDividerText:{
    fontSize:'14px',
    color:'#64748B',
    fontWeight:700,
    lineHwight: '22px'
  },
  longPasswordNew:{
    fontWeight:600,
    margin:'30px 0 10px 0',
    color:'var(--design-secondary-color)',
    fontSize: '16px',
    textAlign:'center' as 'center',
    lineHeight: '24px',
  },
  wecanSend:{
    fontWeight:400,
    margin:'10px 0',
    color:'var(--design-secondary-color)',
    textAlign:'center' as 'center',
    lineHeight: '20px',
    fontSize: '14px',
  },
  mailmeLinkButton:{
    backgroundColor: 'var(--design-accent-color)',
    width: '100%',
    color: 'var(--design-secondary-color)',
    padding: '16px',
    borderRadius: '8px',
    fontSize: '16px',
    gap: '8px',
    lineHeight: '24px',
    fontWeight: 700,
    marginBottom: '10px',
    marginTop: '20px',
  }
}

const PwTextField = styled(TextField)({
  '& fieldset': {
    border: '1px solid #CBD5E1 !important',
  }
})

const StyledLogoutModal = styled(Modal)({
  "& .modalContent":{
  position: "absolute",
  bottom: "0",
  left: "50%",
  transform: "translateX(-50%)",
  maxWidth: "600px",
  width:"100%",
  backgroundColor: "#fff",
  borderRadius: "0px 24px 0px 0px",
  outline: 'none',
  boxSizing: "border-box",
  padding: "70px 43px 24px 43px",
  "& img":{
    position: "absolute",
    top: "20px",
    right: "16px",
    cursor: "pointer"
  }
  },
  "& .modalDesc":{
    fontSize: "18px",
    lineHeight: "32px",
    fontWeight: 700,
    coloe: "#0F172A",
    marginBottom: "30px",
  },
  "& .modalBtns":{
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "4px",
    "& button":{
      borderRadius: "8px",
      width: "120px"
    },
    "& .modalCancelBtn":{
      padding:"12px",
      width: "100%",
      "& span":{
        color: "var(--design-primary-color)",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        textTransform: "capitalize"
      }
    },
    "& .modalLogoutBtn":{
      background: "var(--design-primary-color) !important",
      padding:"12px",
      width: "100%",
      "& span":{
        color: "var(--Basic-White, #FFF)",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        display: "flex",
        gap: "2px",
        "& .txtCp":{
          textTransform: "capitalize"
        },
        "& .txtLo":{
          textTransform: "lowercase"
        }
      }
    }
  }
});

const commondeleteModalContentStyles = {
  left: "50%",
  transform: "translateX(-50%)",
  maxWidth: "626px",
  width: "100%",
  backgroundColor: "#fff",
  padding: "70px 43px 24px 43px",
  outline: 'none',
}

const commondeleteModalImgStyles = {
  top: "26px",
  right: "26px",
}

const commondeleteModalTitleStyles = {
  fontSize: "18px",
  lineHeight: "26px",
  fontWeight: 700,
  color: "#0F172A"
};

const commondeleteModalDescStyles = {
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: 400,
  color: "#0F172A"
};

const commondeleteModalBtnsStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "4px"
};

const commonButtonStyles = {
  borderRadius: "8px",
  width: "151px",
  height: "56px",
};

const commonSpanStyles = {
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "24px",
  display: "flex",
  gap: "2px",
  "& .txtCp": {
    textTransform: "capitalize"
  },
  "& .txtLo": {
    textTransform: "lowercase"
  }
};

const StyledModal = styled(Modal)({
  "& .deleteModalContent":{
    ...commondeleteModalContentStyles,
    bottom: "0",
    borderRadius: "0px 24px 0px 0px",
    position: "absolute",
    boxSizing: "border-box",
    "& img": {
      ...commondeleteModalImgStyles,
      position: "absolute",
      cursor: "pointer",
    },
  },
  "& .deleteModalTitle":{
    ...commondeleteModalTitleStyles,
    marginBottom: "20px",
  },
  "& .deleteModalDesc":{
    ...commondeleteModalDescStyles,
    marginBottom: "30px",
  },
  "& .deleteModalBtns":{
    ...commondeleteModalBtnsStyles,
    flexDirection: "column",
  },
    "& .deleteModalCancelBtn":{
      ...commonButtonStyles,
      background: "var(--design-primary-color) !important",
      width: "100%",
      "& span":{
        ...commonSpanStyles,
        color: "var(--Basic-White, #FFF)",
      }
    },
    "& .deleteModalLogoutBtn":{
      ...commonButtonStyles,
      width: "100%",
      "& span":{
        ...commonSpanStyles,
        color: "#DC2626",
      }
    }
});

// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start
 renderMagicLink = () => {
  return (
  <Box className='main_container' style={this.props.isMobileScreen ? {...webstyle.MainBox, ...webstyle.magicBox} : { ...webstyle.MainBox, ...webstyle.desktopBox }} >
      {this.renderMobileCloseButton()}
      <Grid container style={ this.props.isMobileScreen ? {...webstyle.mobileMainContainer, ...webstyle.magicBox, ...webstyle.magicLinkMainContainer} : webstyle.mainContainer}>
        {this.renderDeskTopCloseButton()}
        <Grid item xs={12}>
          <Grid item lg={12} md={12} sm={12} xs={12} style={webstyle.mainHeadinggrid}>
            <Typography style={{...webstyle.mainHeading,...webstyle.marginBottom2}} data-testid="magic-link">{configJSON.magicLinkSent}</Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography style={webstyle.checkMail}>{configJSON.checkMail}</Typography>
            {this.props.isMobileScreen && <Typography style={{...webstyle.weMailedText, ...webstyle.marginBottom2}}>{configJSON.weMailedText1}</Typography>}
            {!this.props.isMobileScreen && <Box style={webstyle.marginBottom2}>
              <Typography style={webstyle.weMailedText}>{configJSON.weMailedText}<span style={webstyle.emailtext}>{this.state.emailValue}</span></Typography>
              <Typography style={webstyle.weMailedText}>{configJSON.clickLink}</Typography>
            </Box>
            }
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} style={webstyle.mainButtongrid}>
          {this.state.timerStarted ? (
            <ResendTimer
              onTimerComplete={() => startResendTimer(this.setState.bind(this), this.state.countdown)}
              initialTime={this.state.countdown}
              message={`You can resend again in ${this.state.countdown} seconds`} // Dynamically show the remaining time
            />
          ) : (
            <Button
            onClick={() => handleSendMagicLink(this.setState.bind(this), this.state.timerStarted, startResendTimer)}
              disableElevation
              data-testid="resend-button"
              variant="contained"
              style={webstyle.buttonText}
              disabled={this.state.isLoaderOpen}
            >
              {this.state.isLoaderOpen ? <CircularProgress size={27} color="inherit" /> : configJSON.resendMailButton}
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};


renderUserProfile = () => {

  return(
    <Box 
      style={
      {...webstyle.userProfileContainer} 
    }
      >
        {this.renderLogoutModal()}
        {this.renderDeleteAccoutModal()}
         {this.props.isMobileScreen &&
         <IconButton onClick={this.props.onLoginClose} style={webstyle.userProfileClose}>
           <CloseIcon style={webstyle.blackColor} />
         </IconButton>}
      <Box style={webstyle.paddingVertical}>
        <Breadcrumbs separator={<KeyboardArrowRightIcon fontSize="small" />}>
          <Typography onClick={this.props.goToHomePage} style={{...webstyle.body2,...webstyle.pointer}} variant="body2">{configJSON.homeLink}</Typography>
          <Typography style={{...webstyle.body2,...webstyle.pointer,...webstyle.blackColor}} variant="body2">{configJSON.profileLink}</Typography>
        </Breadcrumbs>
        <Box style={webstyle.titleContainer}>
          <Typography style={webstyle.mainHeading} data-testid="login-text">{configJSON.profileLink}</Typography>
        </Box>
      </Box>
      <Box style={{...webstyle.borderBottomContainer,...webstyle.paddingVertical, ...webstyle.flexTwoCon}}>
          <Typography variant="body2" style={{...webstyle.body2, ...webstyle.profileHeading2}}>{configJSON.accountTitle}</Typography>
      </Box>
      <Box style={{ ...webstyle.borderBottomContainer, ...webstyle.flexContainer, ...webstyle.paddingType2, ...webstyle.cursorPointer }} onClick={this.handleProfileClick} data-testid="account-button">
        <Box style={webstyle.profileImageContaier}>
          <img style={webstyle.image} src={account} alt={configJSON.personalDetails} />
          <Typography
            variant="body1"
            style={{ ...webstyle.loginMaintext, ...webstyle.paddingRemove }}
          >
            {configJSON.personalDetails}
          </Typography>
        </Box>
        <IconButton>
          <KeyboardArrowRightIcon style={webstyle.blackColor} />
        </IconButton>
      </Box>
      <Box style={{ ...webstyle.borderBottomContainer, ...webstyle.flexContainer, ...webstyle.paddingType2, ...webstyle.cursorPointer }} onClick={this.handleManageAddress} data-testid="address-button">
        <Box style={webstyle.profileImageContaier}>
          <img style={webstyle.image} src={location} alt={configJSON.manageAddress} />
          <Typography
            variant="body1"
            style={{ ...webstyle.loginMaintext, ...webstyle.paddingRemove }}
          >
            {configJSON.manageAddress}
          </Typography>
        </Box>
        <IconButton>
          <KeyboardArrowRightIcon style={webstyle.blackColor} />
        </IconButton>
      </Box>
      <Box style={{ ...webstyle.borderBottomContainer, ...webstyle.flexContainer, ...webstyle.paddingType2, ...webstyle.cursorPointer }} onClick={this.handleOrderHistory} data-testid="order-history-button">
        <Box style={webstyle.profileImageContaier}>
          <img style={webstyle.image} src={order} alt={configJSON.order} />
          <Typography
            variant="body1"
            style={{ ...webstyle.loginMaintext, ...webstyle.paddingRemove }}
          >
            {configJSON.order}
          </Typography>
        </Box>
        <IconButton>
          <KeyboardArrowRightIcon style={webstyle.blackColor} />
        </IconButton>
      </Box>

      <Box style={{...webstyle.borderBottomContainer,...webstyle.paddingVertical, ...webstyle.flexTwoCon}}>
        <Typography variant="body2" style={{...webstyle.body2,...webstyle.profileHeading2,}}>{configJSON.accountAction}</Typography>
      </Box>
      <Box style={{ ...webstyle.borderBottomContainer, ...webstyle.flexContainer, ...webstyle.paddingType2 }}>
        <Box style={webstyle.profileImageContaier} data-testid="logout-button" onClick={this.handleLogoutModalOpen}>
          <img style={webstyle.image} src={logout} alt={configJSON.logout} />
          <Typography
            variant="body1"
            style={{ ...webstyle.loginMaintext, ...webstyle.paddingRemove }}
          >
            {configJSON.logout}
          </Typography>
        </Box>
      </Box>
      <Box style={{...webstyle.borderBottomContainer, ...webstyle.flexContainer, ...webstyle.paddingType2 }}>
        <Box style={webstyle.profileImageContaier} data-test-id="deleteButtonId" onClick={this.handleDeleteModalOpen}>
          <img style={webstyle.image} src={deleteImage} alt={configJSON.deleteAccount} />
          <Typography
            variant="body1"
            style={{ ...webstyle.loginMaintext, ...webstyle.paddingRemove, ...webstyle.redColor }}
          >
            {configJSON.deleteAccount}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
 }

 renderEmailForm = () => {
  return(
    <Grid item xs={12}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        {this.state.showErroPopup &&
          <Box data-testid='no-email-error' style={webstyle.noAccountBox}>
            <Typography style={webstyle.errorMsgText}>{configJSON.noAccount}</Typography>
          </Box>
        }
        <Typography style={webstyle.firstNametext} data-testid="email-label">{configJSON.emailLabel}</Typography>
        <input data-testid="email-field" placeholder={configJSON.emailPlaceholder} style={this.state.emailErrorMessage ? webstyle.emailErrorField : webstyle.emailInput} value={this.state.emailValue} onChange={this.handleEmailChange} 
         onKeyPress={(event) => handleKeyPress(event, this.handleNext)}/>
        <Typography style={webstyle.emailErrorText} data-testid="email-errormessage">{this.state.emailErrorMessage}</Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} style={webstyle.mainButtongrid}>
        <Button disableElevation variant="contained" style={webstyle.buttonText} data-testid="next-button" onClick={this.handleNext} disabled={this.state.isLoaderOpen}>
          {this.state.isLoaderOpen ? <CircularProgress size={27} color="inherit"/> : configJSON.nextButton}
        </Button>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} style={webstyle.mainlogingrid}>
        <Typography style={webstyle.loginMaintext}>{configJSON.dontHaveAccount}<span style={webstyle.loginSubtext} data-testid="signup-span" onClick={this.props.handleSignUp}>{configJSON.signUp}</span></Typography>
      </Grid>
    </Grid>
  )
 }

  renderMobileCloseButton = () => {
    return this.props.isMobileScreen ? <IconButton onClick={this.props.onLoginClose} style={{ ...webstyle.closeIcon, ...webstyle.closeIconMobile }}>
      <CloseIcon style={webstyle.blackColor} />
    </IconButton> : null
  }

  renderDeskTopCloseButton = () => {
    return this.props.isMobileScreen ? null : <IconButton onClick={this.props.onLoginClose} style={webstyle.closeIcon}>
      <CloseIcon style={webstyle.blackColor} />
    </IconButton>
  }

  renderGetMaicLinkButton = () => {
    return (
      <>
        <Grid item lg={12} md={12} sm={12} xs={12} style={webstyle.magicLinkMainWrapper}>
          <Box style={webstyle.magicLinkDividerWrapper}>
            <Divider style={webstyle.magicLinkDivider} />
            <Typography style={webstyle.orDividerText}>or</Typography>
            <Divider style={webstyle.magicLinkDivider} />
          </Box>
          <Typography style={webstyle.longPasswordNew}>
            {configJSON.longPass}
          </Typography>
          <Typography style={webstyle.wecanSend}>
            {configJSON.wecanSendText}
          </Typography>
          <Button
            data-testid="send-mail-btn"
            style={webstyle.mailmeLinkButton}
            onClick={() => {
              this.loginDirectlyThroughEmail();
              handleSendMagicLink(this.setState.bind(this), this.state.timerStarted, startResendTimer);
            }}
          >
            {this.state.magicLinkLoader ? (
              <CircularProgress size={25} color="inherit" />
            ) : (
              configJSON.mailmeLink
            )}
          </Button>
        </Grid>
      </>
    );
  }

  renderPasswordFeild = () => {
    return (<>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        {this.state.showErroPopup &&
          <Box data-testid='no-email-error' style={webstyle.noAccountBox}>
            <Typography style={webstyle.errorMsgText}>{this.state.showErrorMessage}</Typography>
          </Box>
        }
        <Typography style={webstyle.firstNametext} data-testid="pass-label">{configJSON.placeHolderPassword}</Typography>
        <PwTextField
          variant="outlined"
          fullWidth
          value={this.state.passwordValue}
          onChange={this.handlePassword}
          placeholder={configJSON.enterYourPlaceholder}
          type={this.state.enablePasswordField ? "text" : "password"}
          style={webstyle.passwordField}
          data-testid="passwrd-field"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" onClick={() => this.handleClickShowPassword()}>
                {this.state.enablePasswordField ? (
                  <VisibilityOutlinedIcon style={webstyle.eyeIcon} />
                ) : (
                  <VisibilityOffOutlinedIcon style={webstyle.eyeIcon} />
                )}
              </InputAdornment>
            ),
            style: webstyle.inputProps
          }}
          inputProps={{ style: webstyle.passInputProps }}
          error={this.state.emailError}
          helperText={this.state.emailError && this.state.emailErrorMessage}
          onKeyPress={(event) => handleKeyPress(event, this.handleLogin)}
        />
        <Typography style={webstyle.forgotPassword}
          onClick={this.handleForgotPwBtnClick}
          data-testid="forgot-password-btn"
        >{configJSON.forgotpass}</Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} style={webstyle.mainButtongrid}>
        <Button
          disableElevation
          variant="contained"
          data-testid="login-button"
          style={webstyle.buttonText}
          onClick={this.handleLogin}
          disabled={this.state.isLoaderOpen && this.state.activeButton === "login"}
        >
          {(this.state.isLoaderOpen && this.state.activeButton === "login") ? <CircularProgress size={27} color="inherit"/> : configJSON.labelTitle}
        </Button>
      </Grid>
      {this.renderGetMaicLinkButton()}
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Typography style={this.props.isMobileScreen ? { ...webstyle.backtotext, ...webstyle.backButttonTextAlign, } : webstyle.backtotext}>{configJSON.backTo}<span style={webstyle.loginSpan} data-testid="back-to-login" onClick={this.handleBackToLogin}>{configJSON.labelTitle}</span></Typography>
      </Grid>
    </>)
  }

 renderLoginForm = () => {
  if (this.state.showForgotPassword) {
    return this.renderForgotPassword()
  }
   return (
     <Box style={this.props.isMobileScreen ? { ...webstyle.MainBox, ...webstyle.magicBox } : { ...webstyle.MainBox, ...webstyle.desktopBox }} >
        {this.renderMobileCloseButton()}
       <Grid container style={this.props.isMobileScreen ? {...webstyle.mobileMainContainer, ...webstyle.backgroundNone} : webstyle.mainContainer}>
         {this.renderDeskTopCloseButton()}
         <Grid item lg={12} md={12} sm={12} xs={12} style={webstyle.mainHeadinggrid}>
           <Typography style={webstyle.mainHeading} data-testid="login-text">{configJSON.labelTitle}</Typography>
           {this.state.showPassField && <Typography style={webstyle.emailText}> {this.state.emailValue}</Typography>}
         </Grid>
         {this.state.showPassField ?
          this.renderPasswordFeild()
           :
           <>
             {this.props.isMobileScreen ? 
             <Box sx={webstyle.fullWidthContainer}>
               {this.renderEmailForm()}
             </Box> : 
             this.renderEmailForm()
             }
           </>
         }
       </Grid>
     </Box>
   )
 }

  renderPwResetSuccess = () => {
    return (
      <Box style={this.props.isMobileScreen ? { ...webstyle.MainBox, ...webstyle.magicBox } : { ...webstyle.MainBox, ...webstyle.desktopBox }} >
        {this.renderMobileCloseButton()}
        <Grid container style={this.props.isMobileScreen ? { ...webstyle.mobileMainContainer, ...webstyle.backgroundNone } : webstyle.mainContainer}>
          {this.renderDeskTopCloseButton()}
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <img src={checkbox} alt="success icon" style={webstyle.successImg} />
            <Typography style={webstyle.mainHeadingFP}>{configJSON.successReset}</Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} style={webstyle.mainHeadinggrid}>
            <Typography style={webstyle.loginMaintextFP}>{configJSON.successResetDesc}</Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} style={webstyle.mainButtongridForgotPw}>
            <Button disableElevation variant="contained" style={webstyle.buttonText} data-testid="send-button" onClick={this.props.onLoginClose}>
              {configJSON.labelTitle}
            </Button>
          </Grid>
        </Grid>
      </Box>
    )
  }

  renderSetNewPw = () => {
    return (
      <Box style={this.props.isMobileScreen ? { ...webstyle.MainBox, ...webstyle.magicBox } : { ...webstyle.MainBox, ...webstyle.desktopBox }} >
        {this.renderMobileCloseButton()}
        <Grid container style={this.props.isMobileScreen ? { ...webstyle.mobileMainContainer, ...webstyle.backgroundNone } : webstyle.mainContainer}>
          {this.renderDeskTopCloseButton()}
          <Grid item lg={12} md={12} sm={12} xs={12} style={webstyle.mainHeadinggrid}>
            <Typography style={webstyle.mainHeading}>{configJSON.setNewPw}</Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography style={webstyle.firstNametext}>{configJSON.newPw}</Typography>
            <PwTextField
              variant="outlined"
              fullWidth
              value={this.state.newPassword}
              onChange={this.handleNewPassword}
              placeholder={configJSON.placeholderNewPw}
              type={this.state.enablePasswordField ? "text" : "password"}
              style={webstyle.passwordField}
              data-testid="new-password-field"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" onClick={() => this.handleClickShowPassword()}>
                    {this.state.enablePasswordField ? (
                      <VisibilityOutlinedIcon style={webstyle.eyeIcon} />
                    ) : (
                      <VisibilityOffOutlinedIcon style={webstyle.eyeIcon} />
                    )}
                  </InputAdornment>
                ),
                style: webstyle.inputProps
              }}
              inputProps={{ style: webstyle.passInputProps }}
              error={this.state.newPasswordError}
              helperText={this.state.newPasswordError && this.state.newPasswordErrorMessage}
            />
            <Typography style={webstyle.longPassword}>{configJSON.passwordLength}</Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} style={webstyle.mainHeadinggridFPw}>
            <Typography style={webstyle.firstNametext}>{configJSON.confirmPw}</Typography>
            <PwTextField
              variant="outlined"
              fullWidth
              value={this.state.confirmPassword}
              onChange={this.handleConfirmPassword}
              placeholder={configJSON.placeholderConfirmPw}
              type={this.state.enablePasswordField_2 ? "text" : "password"}
              style={webstyle.passwordField}
              data-testid="confirm-password-field"
              onKeyPress={(event) => handleKeyPress(event, this.handleSetNewPassword)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" onClick={() => this.handleClickShowPassword(true)}>
                    {this.state.enablePasswordField_2 ? (
                      <VisibilityOutlinedIcon style={webstyle.eyeIcon} />
                    ) : (
                      <VisibilityOffOutlinedIcon style={webstyle.eyeIcon} />
                    )}
                  </InputAdornment>
                ),
                style: webstyle.inputProps
              }}
              inputProps={{ style: webstyle.passInputProps }}
              error={this.state.confirmPasswordError}
              helperText={this.state.confirmPasswordError && this.state.confirmPasswordErrorMessage}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} style={webstyle.mainButtongridForgotPw}>
            <Button disableElevation variant="contained" style={webstyle.buttonText} data-testid="set-password-button" onClick={this.handleSetNewPassword} disabled={this.state.isLoaderOpen}>
              {this.state.isLoaderOpen ? <CircularProgress size={27} color="inherit"/> : configJSON.setNewPw}
            </Button>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography style={webstyle.loginMaintext}>{configJSON.backTo}<span style={webstyle.loginSubtext} onClick={this.handleBackToLogin}>{configJSON.labelTitle}</span></Typography>
          </Grid>
        </Grid>
      </Box>
    )
  }

  renderPwResetResend = () => {
    return (
      <Box style={this.props.isMobileScreen ? { ...webstyle.MainBox, ...webstyle.magicBox } : { ...webstyle.MainBox, ...webstyle.desktopBox }} >
        {this.renderMobileCloseButton()}
        <Grid container style={this.props.isMobileScreen ? { ...webstyle.mobileMainContainer, ...webstyle.backgroundNone } : webstyle.mainContainer}>
          {this.renderDeskTopCloseButton()}
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography style={webstyle.mainHeading}>{configJSON.passwordRecov}</Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} style={webstyle.mainHeadinggrid}>
            <Typography style={webstyle.checkMail}>{configJSON.checkMail}</Typography>
            <Typography style={webstyle.loginMaintext}>{configJSON.weSentReset}</Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} style={webstyle.mainButtongridForgotPw}>
          {this.state.isResendDisabled ? (
              <ResendTimer onTimerComplete={() => startResendTimer(this.setState.bind(this), this.state.countdown)} initialTime={this.state.countdown} message={`You can resend again in ${this.state.countdown} seconds`} />
            ) :<Button disableElevation variant="contained" style={webstyle.buttonText} data-testid="send-button" onClick={() =>{
              this.handleSendRecoveryLink();
              handleSendForgotPasswordLink(this.setState.bind(this), startResendTimer);
            }} disabled={this.state.isLoaderOpen}>
              {this.state.isLoaderOpen ? <CircularProgress size={27} color="inherit"/> : configJSON.resendMailButton}
            </Button>}
          </Grid>
        </Grid>
      </Box>
    )
  }

 renderForgotPassword = () => {
  if (this.state.pwResetSuccess) {
    return this.renderPwResetSuccess()
  } else if (this.state.setNewPwScreen) {
    return this.renderSetNewPw()
  } else if (this.state.pwResetResendLink) {
    return this.renderPwResetResend()
  }

  return (
    <Box style={this.props.isMobileScreen ? { ...webstyle.MainBox, ...webstyle.magicBox } : { ...webstyle.MainBox, ...webstyle.desktopBox }} >
      {this.renderMobileCloseButton()}
      <Grid container style={this.props.isMobileScreen ? { ...webstyle.mobileMainContainer, ...webstyle.backgroundNone } : webstyle.mainContainer}>
        {this.renderDeskTopCloseButton()}
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography style={webstyle.mainHeading}>{configJSON.passwordRecov}</Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} style={webstyle.mainHeadinggrid}>
          <Typography style={webstyle.checkMail}>{configJSON.forgotpass}</Typography>
          <Typography style={webstyle.loginMaintext}>{configJSON.noWorriesText}</Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} style={webstyle.mainButtongridForgotPw}>
          <Button disableElevation variant="contained" style={webstyle.buttonText} data-testid="send-button" onClick={() =>{
            this.handleSendRecoveryLink();
          }} disabled={this.state.isLoaderOpen}>
            {this.state.isLoaderOpen ? <CircularProgress size={27} color="inherit"/> :  configJSON.sendRecov}
          </Button>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography style={webstyle.loginMaintext}>{configJSON.backTo}<span style={webstyle.loginSubtext} onClick={this.handleBackToLogin}>{configJSON.labelTitle}</span></Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

  renderLogoutModal = () => {
  return (
    <StyledLogoutModal
      open={this.state.isLogoutModalOpen}
      onClose={this.handleLogoutModalClose}
    >
      <Box className="modalContent">
        <IconButton onClick={this.handleLogoutModalClose} style={{ ...webstyle.closeIcon, ...webstyle.closeIconMobile }}>
           <CloseIcon style={webstyle.blackColor} />
         </IconButton>
        <Typography className="modalDesc">Are you sure you want to log out of your account?</Typography>
        <Box className="modalBtns">
          <Button data-test-id="cancleLogoutButtonId" className = "modalCancelBtn" onClick={this.handleLogoutModalClose}>Cancel</Button>
          <Button onClick={this.handleLogOut} className="modalLogoutBtn"><span className="txtCp">Log</span> <span className="txtLo">out</span></Button>
        </Box>
      </Box>
    </StyledLogoutModal>
  )
};

  renderDeleteAccoutModal = () => {
  return (
    <StyledModal
      open={this.state.isDeleteModalOpen}
      onClose={this.handleDeleteModalClose}
      data-testid="delete-modal"
    >
      <Box className="deleteModalContent">
        <IconButton onClick={this.handleDeleteModalClose} style={{ ...webstyle.closeIcon, ...webstyle.closeIconMobile }}>
           <CloseIcon style={webstyle.blackColor} />
        </IconButton>
        <Typography className="deleteModalTitle">Are you sure you want to delete your account?</Typography>
        <Typography className="deleteModalDesc">This action can’t be undone and all the data stored in this account won’t be accessible anymore.</Typography>
          <Box className="deleteModalBtns">
          <Button data-test-id="submitDeleteButton" className="deleteModalLogoutBtn" onClick={this.deleteUser}><span className="txtCp">Delete</span> <span className="txtLo">account</span></Button>
          <Button data-test-id="cancleButtonId" className = "deleteModalCancelBtn" onClick={this.handleDeleteModalClose}><span className="txtCp">Keep</span> <span className="txtLo">account</span></Button>
        </Box>
      </Box>
    </StyledModal>
  )
};
 // Customizable Area End
  render() {
    return (
       // Customizable Area Start
      <ThemeProvider theme={theme}>
        <>
          {this.props.screenType !== 'profile' && <Modal
            disableAutoFocus
            disableEnforceFocus
            open={this.props.isOpen}
            BackdropProps={{
              style:
                this.props.isMobileScreen ? webstyle.mobileBackground : webstyle.desktopBackground
            }}
          >
            <>
              {this.props.screenType === "login" && this.renderLoginForm()}
              {this.props.screenType === "magic" && this.renderMagicLink()}
            </>

          </Modal>}
          {(this.props.screenType === 'profile' || this.props.showProfile) && <Modal
            disableAutoFocus
            open={this.props.isOpen}
            disableEnforceFocus
            BackdropProps={{ style: { ...webstyle.mobileBackground, ...webstyle.paddingRemove } }}
          >
            <>
              {this.renderUserProfile()}
            </>

          </Modal>}
        </>
      </ThemeProvider>
       // Customizable Area End
    );
  }
}
