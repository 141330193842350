import { IBlock } from '../../../../framework/src/IBlock'
import { Message } from '../../../../framework/src/Message'
import { BlockComponent } from '../../../../framework/src/BlockComponent'
import MessageEnum, { getName } from '../Messages/MessageEnum'
import { runEngine } from '../../../../framework/src/RunEngine'

// Customizable Area Start
import { withLoaderProps } from '../HOC/withLoader.Web'
import { withToastProps } from '../HOC/withSnackBar.Web'
import { RouterProps } from 'react-router'
import { parseExpireTokenResponse, logoutAndRedirectToLoginPage } from '../Utilities/Utilities'
import { getStorageData, setStorageData } from '../../../../framework/src/Utilities'
import * as configcat from "configcat-js";
import { configCatClient } from '../../../../components/src/FeatureFlag'
const baseURL = require("../../../../framework/src/config.js").baseURL;


// Customizable Area End

export const configJSON = require('./config')

export type Props = RouterProps &
  withToastProps &
  withLoaderProps & {
    id: string
    // Customizable Area Start
    // Customizable Area End
  }

interface S {
  // Customizable Area Start
  token: string
  countryName:string 
  adminEmail:string
  tierType: string
  email:string,
  build_card:string,
  isEnableCustomerFeedback :boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any
  // Customizable Area End
}

export default class NavController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCurrentPlanApiCallId: string = ''
  getAdminDataCallId: string = ''
  getCustomerServiceApiEndpointCallId : string =''
  // Customizable Area End

  constructor(props: Props) {
    super(props)
    this.receive = this.receive.bind(this)

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End

    ]
    this.state = {
      // Customizable Area Start
      token: '',
      tierType: "",
      countryName:'',
      adminEmail:'',
      email:'',
      build_card:'',
      isEnableCustomerFeedback:false
      // Customizable Area End

    }

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
       
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
      if (apiRequestCallId === this.getCustomerServiceApiEndpointCallId) {
        if (responseJson) {
          const attr = responseJson.data.attributes;
          this.setState(
            { countryName: attr?.country_name, adminEmail: attr?.email },
            () => this.featureFlag()
          );
        }
      }
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage))
      if(apiRequestCallId === this.getAdminDataCallId){
        if(responseJson && responseJson.data){
          responseJson.data.attributes.permissions = ["all"];
          setStorageData("adminuser",JSON.stringify(responseJson))

        }
      }
      if (parseExpireTokenResponse(responseJson, this.state, this.props)) {
        if (apiRequestCallId != null) {
          if (apiRequestCallId === this.getCurrentPlanApiCallId) {
            // this.props.hideLoader()
            if (responseJson?.build_card_type) {
              const tierType = responseJson?.tier_type
              this.setState({ tierType })
              setStorageData('tierType', tierType)
            } else {
              this.parseApiErrorResponse(responseJson)
            }
          } 
        
        }
      } else {
        logoutAndRedirectToLoginPage(this.props)
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage))
      const AlertBodyMessage = message.getData(getName(MessageEnum.AlertBodyMessage))

      if (AlertBodyMessage === 'State Not Found') return
			else {
				this.props.showToast({
					type: 'error',
					message: AlertBodyMessage,
				});
			}
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async getAdminProfile() {
    const headers = {
      token:await getStorageData('admintoken'),
    }

    const getAdminData = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getAdminDataCallId = getAdminData.messageId
    const apiWithMarker = configJSON.accountDetailsAPI;

    getAdminData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiWithMarker
    )

    getAdminData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )
    getAdminData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getCurrentPlanMethodType
    )
    runEngine.sendMessage(getAdminData.id, getAdminData)
  

    }

  async getCurrentPlan() {
    if (this.state.tierType !== '') {
      return
    }
    // this.props.showLoader()
    const headers = {
      token:await getStorageData('admintoken'),
      apptoken: configJSON.getCurrentPlanAppToken || 'iCxBWGyfgL89NPCwkc-i'
    }

    const getCurrentPlanData = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getCurrentPlanApiCallId = getCurrentPlanData.messageId
    const apiWithMarker = configJSON.getCurrentPlanAPI;
    apiWithMarker[1] = await getStorageData("build_card_id")

    getCurrentPlanData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiWithMarker.join("")
    )

    getCurrentPlanData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )
    getCurrentPlanData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getCurrentPlanMethodType
    )
    runEngine.sendMessage(getCurrentPlanData.id, getCurrentPlanData)
  }

  async getCustomerServiceApiEndpoint () { 
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCustomerServiceApiEndpointCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_store_details/store_details"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

   featureFlag = async () => {
      let identifier = this.state.build_card + "" + this.state.adminEmail;
  
      const userObject = new configcat.User(
        identifier,
        this.state.adminEmail,
        this.state.countryName,
        {
          buildCardId: this.state.build_card,
          Platform: "web",
          URL: baseURL,
        }
      );
      configCatClient
        .getValueAsync("testimonials", false, userObject)
        .then((value) => { 
        this.setState({isEnableCustomerFeedback :value})
        });
    };

  // Customizable Area End

}
