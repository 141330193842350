import React from "react";
// Customizable Area Start
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Search from '@material-ui/icons/Search';
import HelpCentreController, { configJSON } from "./HelpCentreController";

import "../assets/style.css";
import { Breadcrumbs } from "@material-ui/core";
import { NavigateNextRounded } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
// Customizable Area End

export class HelpCentre extends HelpCentreController {

  // Customizable Area Start
  renderSkeletonHelpCentreItem = () => (
    <div className="right-content-help-item">
      <Skeleton variant="rect" animation="wave" width="100%" height={20} />
      <Skeleton variant="text" animation="wave" width="100%" height={20} />
    </div>
  )
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { 
      mainPageOpen,
      activePage,
      activePageItems,
      filteredStaticPagesItems,
      searchValue,
      isLoading,
      isMobile
    } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <div className="help-centre-wrapper">
        <Breadcrumbs
          className="help-center-breadcrumb"
          separator={<NavigateNextRounded fontSize="small" className="help-center__breadcrumb__separator" />}
          aria-label="breadcrumb"
          style={{ backgroundColor: mainPageOpen ? "#fff" : "transparent" }}
        >
          <p className="help-center__breadcrumb__home" onClick={this.handleDirectToHome}>{"Home"}</p>
          <p onClick={this.handleBreadcrumbItemClick}>{configJSON.helpCentre}</p>
          {mainPageOpen ? null : activePage && <p>{activePage.title}</p>}
        </Breadcrumbs>
        {(!isMobile || mainPageOpen) &&
        <div className="left-sidebar">
          <div className="left-sidebar-top-area">
            <button className="arrow-back-button" onClick={this.handleDirectToHome}>
              <ArrowBackIcon fontSize="small" />
              <span>{configJSON.back}</span>
            </button>
            <h1 className="title">{configJSON.helpCentre}</h1>
          </div>
          <div className="left-sidebar-bottom-area">
            <div className="help-pages-list">
              {filteredStaticPagesItems?.map((item, index: number) => (
                <button key={index} className={"help-pages-list-item" + (activePage.title === item.title ? " active" : "")} onClick={() => this.handleMenuItemClick(item)}>{item.title}</button>
              ))}
            </div>
          </div>
        </div>}
        {(!isMobile || !mainPageOpen) &&
        <div className="right-content">
          {!isLoading ? activePageItems?.map((item, index: number) => (
            <div className="right-content-help-item" key={index}>
              <h3 data-testid='title'>{item.title}</h3>
              <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
            </div>
          )) : this.renderSkeletonHelpCentreItem()}
        </div>
      }
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default HelpCentre;
// Customizable Area End