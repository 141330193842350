// @ts-nocheck
import * as React from 'react';
// Customizable Area Start
import { List } from '@material-ui/core';
import RenderLink from './RenderLink';
import './assets/css/style.css';
import withToast from "../HOC/withSnackBar.Web";
import { getStorageData } from "../../../../framework/src/Utilities";
import NavController from './NavController.web'
export const configJSON = require('./config')
// Customizable Area End
class NavComponent extends NavController {
    constructor(props: any) {
        super(props);
        // @ts-ignore
        this.state = {
            // @ts-ignore
            // Customizable Area Start
            adminuser: null,
            loading: true,
            links: [
                {
                    id: 'home',
                    label: 'Home',
                    iconName: 'icon-union',
                    link: '/admin'
                },
                {
                    id: 'order',
                    label: 'Orders',
                    iconName: 'icon-orders',
                    link: '/admin/orders'
                },
                {
                    id: 'inventory',
                    label: 'Inventory',
                    iconName: 'icon-products',
                    subLinks: [
                        {
                            id: 'product',
                            label: 'Products',
                            link: '/admin/products'
                        },
                        {
                            id: 'category',
                            label: 'Categories',
                            link: '/admin/categories'
                        },
                        {
                            id: 'brand',
                            label: 'Brands',
                            link: '/admin/brands'
                        },
                        {
                            id: 'variant',
                            label: 'Variants',
                            link: '/admin/variants'
                        }

                    ]
                },
                {
                    id: 'user',
                    label: 'Customers',
                    iconName: 'icon-customer',
                    subLinks: [
                        {
                            id: 'contact',
                            label: 'Contacts',
                            link: '/admin/customers'
                        },
                        {
                            id: 'inbound-queries',
                            label: 'Inbound Queries',
                            link: '/admin/inbound-queries'
                        },
                        {
                            id: 'customer-feedback',
                            label: 'Customer Feedback',
                            link: '/admin/AdminCustomerFeedback'
                        },
                    ]
                },
                {
                    id: 'website-email',
                    label: 'Website & emails',
                    iconName: 'icon-website',
                    subLinks: [
                        {
                            id: 'branding',
                            label: 'Branding',
                            link: '/admin/Branding'
                        },
                        {
                            id: 'email setting',
                            label: 'Email Templates',
                            link: '/admin/AdminEmailTemplates'
                        },
                        {
                            id: 'static',
                            label: 'Static Pages',
                            link: '/admin/AdminStaticPage'
                        },
                        {
                            id: 'image',
                            label: 'Image Catalogue',
                            link: '/admin/AdminImageCatalogue'
                        },
                    ]
                },
                {
                    id: 'business',
                    label: 'Business Settings',
                    iconName: 'icon-settings',
                    subLinks: 
                    (window.location.href.includes('dev') && window.location.href.includes('builder.cafe') || 
                    window.location.href.includes('stage') && window.location.href.includes('builder.ai') ||
                    window.location.href.includes('localhost')) ?
                    [
                        {
                            id: 'store',
                            label: 'Store details',
                            link: '/admin/AdminStoreDetails'
                        },
                        {
                            id: 'notifications',
                            label: 'Notifications',
                            link: '/admin/AdminNotifications'
                        },
                        {
                            id: 'tax',
                            label: 'Taxes',
                            link: '/admin/AdminTaxes'
                        },
                        {
                            id: 'shipping',
                            label: 'Shipping',
                            link: '/admin/AdminShipping'
                        },
                        
                        // {
                        //     id: 'promoCode',
                        //     label: 'Promo Code',
                        //     link: '/admin/AdminPromoCode'
                        // },
                        {
                            id: 'paymentIntegration',
                            label: 'Payment',
                            link: '/admin/AdminPaymentIntegration'
                        },
                        {
                            id: 'mobile_app',
                            label: 'Mobile App Settings',
                            link: '/admin/AdminMobileApp'
                        },
                        {
                            id: 'otherIntegrations',
                            label: 'Other integrations',
                            link: '/admin/AdminOtherIntegrations'
                        }
                    ] : 
                    [
                        {
                            id: 'store',
                            label: 'Store details',
                            link: '/admin/AdminStoreDetails'
                        },
                        {
                            id: 'tax',
                            label: 'Taxes',
                            link: '/admin/AdminTaxes'
                        },
                        {
                            id: 'shipping',
                            label: 'Shipping',
                            link: '/admin/AdminShipping'
                        },
                        
                        // {
                        //     id: 'promoCode',
                        //     label: 'Promo Code',
                        //     link: '/admin/AdminPromoCode'
                        // },
                        {
                            id: 'paymentIntegration',
                            label: 'Payment',
                            link: '/admin/AdminPaymentIntegration'
                        },
                        {
                            id: 'mobile_app',
                            label: 'Mobile App Settings',
                            link: '/admin/AdminMobileApp'
                        },
                        {
                            id: 'otherIntegrations',
                            label: 'Other integrations',
                            link: '/admin/AdminOtherIntegrations'
                        }
                    ]
                },
                {
                    id: 'marketing',
                    label: 'Marketing',
                    iconName: 'icon-star',
                    subLinks: [
                        {
                            id: 'profile',
                            label: 'SEO',
                            iconName: '',
                            link: '/admin/seo-checklist'
                        },
                        {
                            id: 'lead-generation',
                            label: 'Lead Generation',
                            link: '/admin/marketing'
                        },
                        {
                            id:'promotions',
                            label:"Promotions",
                            link:'/admin/promotions'
                        }
                    ]
                },
                {
                    id: 'account',
                    label: 'Account',
                    iconName: 'icon-user',
                    subLinks: [
                        {
                            id: 'profile',
                            label: 'Profile',
                            iconName: '',
                            link: '/admin/sercivesspecificsetting'
                        },
                    ]
                }
            ],
            tierType: "",
            // Customizable Area End
        }
    }
    // Customizable Area Start
    async componentDidMount() {
        const adminUser = await getStorageData("adminuser")
         const token = await getStorageData("admintoken");
        this.getCustomerServiceApiEndpoint();
        if (adminUser && typeof adminUser == "string") {
          const adminData = JSON.parse(adminUser).data.attributes;
          this.setState({
            ...this.state,
            email: adminData.email,
            build_card: adminData.build_card,
          });
        }
        this.getAdminProfile()
        if (token && adminUser) {
            this.setState({
                adminuser: JSON.parse(adminUser) || {},
                loading: false
            })
        }
    }
    // Customizable Area End
    render() {
        // @ts-ignore
        // Customizable Area Start
        const adminuser = this.state.adminuser;
        const permissions = adminuser?.data?.attributes?.permissions;
        const permissionLinks = (data: any) => {
            if (data?.id && data?.subLinks) {
                const value1 = [];
                data?.subLinks?.map((values: any) => permissions?.find(permission => {
                    if (permission === values?.id) {
                        value1?.push(values)
                        return values
                    }
                }))
                data.subLinks = value1;
                return data?.subLinks?.length ? data : undefined;
            }
            if (data) {
                const value2 = [];
                permissions?.find(permission => {
                    if (permission === data?.id) {
                        value2?.push(data)
                        return data
                    }
                })
                return value2[0];
            }

        }
        // @ts-ignore
        return (
            <List
                className='admin-nav'
                tabs
                vertical
            >
                {permissions && this.state?.links.length &&
                    this.state?.links.map((data: any, index: number) => (
                        permissions.includes("all")
                            ? <RenderLink data={data} tierType={this.state.tierType} state={this.state} />
                            : (index === 0 && permissions.includes("order")) ? <RenderLink data={this.state.links[0]} tierType={this.state.tierType} state={this.state} />
                                : permissionLinks(data) !== undefined
                                    ? <RenderLink data={permissionLinks(data)} tierType={this.state.tierType} state={this.state} /> : ''
                    ))
                }
            </List>
        );

    }
    // Customizable Area End

}

export const Nav = withToast(NavComponent);