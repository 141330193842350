/* eslint-disable max-classes-per-file, no-restricted-syntax */
import React, { useState, useEffect } from 'react';
export const startResendTimer = (
  setState: React.Dispatch<React.SetStateAction<any>>, 
  countdown: number
) => {
  setState({ isResendDisabled: true, countdown });

  const timer = setInterval(() => {
    setState((prevState: any) => {
      if (prevState.countdown <= 1) {
        clearInterval(timer);
        return { isResendDisabled: false, timerStarted: false, countdown: 0 };
      }
      return { ...prevState, countdown: prevState.countdown - 1 };
    });
  }, 1000);
};

export const handleSendMagicLink = (
  setState: React.Dispatch<React.SetStateAction<any>>, 
  timerStarted: boolean, 
  startResendTimer: Function
) => {
  setState({ isLoaderOpen: true });

  setTimeout(() => {
    setState({ isLoaderOpen: false });
    if (!timerStarted) {
      setState({ timerStarted: true });
    }
    startResendTimer(setState, 30);
  }, 1000);
};

export const handleSendForgotPasswordLink = (
  setState: React.Dispatch<React.SetStateAction<any>>, 
  startResendTimer: Function
) => {
  setState({ isLoaderOpen: true });

  setTimeout(() => {
    setState({ isLoaderOpen: false });
    startResendTimer(setState, 30);
  }, 1000);
};

export const handleKeyPress = (event: any, callback: () => void) => {
  if (event.key === 'Enter') {
    callback(); 
  }
};

interface ResendTimerProps {
  onTimerComplete: () => void;
  initialTime: number;
  message: string;
}

const ResendTimer: React.FC<ResendTimerProps> = ({ onTimerComplete, initialTime, message }) => {
  const [seconds, setSeconds] = useState<number>(initialTime);
  const [isTimerActive, setIsTimerActive] = useState<boolean>(true);

  useEffect(() => {
    setSeconds(initialTime);
    setIsTimerActive(true);
  }, [initialTime]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (isTimerActive && seconds > 0) {
      intervalId = setInterval(() => {
        setSeconds((prev) => {
          if (prev <= 1) {
            clearInterval(intervalId);
            onTimerComplete();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [seconds, isTimerActive, onTimerComplete]);

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "60px",
          height: "60px",
          borderRadius: "50%",
          backgroundColor: "#8833FF",
          color: "#fff",
          fontSize: "24px",
          fontWeight: "bold",
          marginBottom: "10px",
        }}
      >
        {seconds}
      </div>
      <p>{message.replace(/\d+ seconds/, `${seconds} seconds`)}</p>
    </div>
  );
};

export default ResendTimer;
